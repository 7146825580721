<template>
  <div>
    <!--    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="4" :sm="24">
            <a-form-item label="名称">
              <a-input v-model="queryParam.name" placeholder="请输入名称" allow-clear/>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>-->
    <!--    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
    </div>-->
    <div>记录</div>
    <s-table
      style="margin-bottom: 40px"
      ref="table"
      size="middle"
      rowKey="id"
      :loading="loading"
      :columns="columnsRecord"
      :data="loadDataRecord"
      :show-pagination="false"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleRefund(record)">退款</a>
        </template>
      </span>
    </s-table>
    <div>保税商品</div>
    <s-table
      style="margin-bottom: 40px"
      ref="table"
      size="middle"
      rowKey="id"
      :loading="loading"
      :columns="columnsCommodity"
      :data="loadDataCommodity"
      :show-pagination="false"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="money_render" slot-scope="text" style="text-align: right; min-width: 70px; max-width: 80px">
        {{ (text / 100).toFixed(2) }}
      </div>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleRefund(record)" v-if="record.status === 2">退款</a>
        </template>
      </span>
    </s-table>
    <div>普通商品</div>
    <s-table
      ref="table"
      size="middle"
      rowKey="id"
      :loading="loading"
      :columns="columnsNormalCommodity"
      :data="loadDataNormalCommodity"
      :show-pagination="false"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="money_render" slot-scope="text" style="text-align: right; min-width: 90px; max-width: 100px">
        {{ (text / 100).toFixed(2) }}
      </div>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleRefund(record)" v-if="record.status === 2">退款</a>
        </template>
      </span>
    </s-table>
  </div>
</template>

<script>
import { STable } from '@/components'
import { Dictionaries } from '@/common/AllConstant'
import CreateForm from '../modules/CreateForm'

import {
  exhibition_order_commodity_list,
  exhibition_order_normal_commodity_list,
  exhibition_order_record_list
} from '@/api/exhibition'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm
  },
  data () {
    return {
      loading: false,
      confirmLoading: false,
      current_bill_id: 0,
      // 创建窗口控制
      visible: false,
      mdl: {},
      payconfirmLoading: false,
      // 创建窗口控制
      payvisible: false,
      paymdl: {},
      dictionaries: Dictionaries,
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columnsRecord: [
        {
          title: '操作人',
          align: 'center',
          customRender: (text) => text || '-',
          dataIndex: 'operate_by'
        },
        {
          title: '状态',
          align: 'center',
          dataIndex: 'status',
          customRender: (text) => this.$Dictionaries.OrderStatus[text]
        },
        {
          title: '创建时间',
          align: 'center',
          customRender: (text) => text || '-',
          dataIndex: 'create_time'
        }
      ],
      columnsCommodity: [
        {
          title: '名称',
          align: 'center',
          dataIndex: 'name'
        },
        {
          title: '数量',
          align: 'center',
          dataIndex: 'exhibition_count'
        },
        {
          title: '金额',
          align: 'center',
          dataIndex: 'amount',
          scopedSlots: { customRender: 'money_render' }
        },
        {
          title: 'QID',
          align: 'center',
          dataIndex: 'qid'
        },
        {
          title: '状态',
          align: 'center',
          dataIndex: 'status',
          customRender: (text) => this.$Dictionaries.OrderStatus[text]
        },
        {
          title: '是否报关',
          align: 'center',
          dataIndex: 'is_customs_declaration',
          customRender: (text) => text && '是' || '否'
        }
      ],
      columnsNormalCommodity: [
        {
          title: '名称',
          align: 'center',
          dataIndex: 'name'
        },
        {
          title: '数量',
          align: 'center',
          dataIndex: 'count'
        },
        {
          title: '金额',
          align: 'center',
          scopedSlots: { customRender: 'money_render' },
          dataIndex: 'amount'
        },
        {
          title: '状态',
          align: 'center',
          dataIndex: 'status',
          customRender: (text) => this.$Dictionaries.OrderStatus[text]
        }
      ],

      // 加载数据方法 必须为 Promise 对象
      loadDataCommodity: parameter => {
        console.log('保税', parameter)
        return exhibition_order_commodity_list({ order_id: this.$route.query.id })
          .then(({ data }) => {
            console.log(data, '我是数据列表@')
            return data
          })
      },
      // 加载数据方法 必须为 Promise 对象
      loadDataNormalCommodity: parameter => {
        console.log('普通', parameter)
        return exhibition_order_normal_commodity_list({ order_id: this.$route.query.id })
          .then(({ data }) => {
            console.log(data, '我是数据列表@')
            return data
          })
      },
      // 加载数据方法 必须为 Promise 对象
      loadDataRecord: parameter => {
        console.log('记录', this.$route.query.id)
        return exhibition_order_record_list(this.$route.query.id)
          .then(({ data }) => {
            console.log(data, '我是数据列表@')
            return { entries: data }
          })
      }
    }
  },
  created () {
    console.log(this.$Dictionaries)
  },
  methods: {
    handleRefund (record) {
      console.log(record)
    }
  }
}
</script>
